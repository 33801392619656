import React from 'react';
import { Typography, Box } from '@mui/material';

const HomePage: React.FC = () => {
  return (
    <Box sx={{ textAlign: { xs: 'center', sm: 'left' } }}>
      <Typography variant="h2" component="h1" gutterBottom sx={{ fontSize: { xs: '2.5rem', sm: '3.75rem' } }}>
        Welcome 
      </Typography>
      <Typography variant="body1" sx={{ fontSize: { xs: '1rem', sm: '1.1rem' } }}>
        Feel free to explore!
      </Typography>
    </Box>
  );
};

export default HomePage;