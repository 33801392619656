import React from 'react';
import { Container, Box, Typography } from '@mui/material';
import { Outlet } from 'react-router-dom';
import TopNavigation from '../components/TopNavigation';

const MainLayout: React.FC = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <TopNavigation />
      <Container component="main" sx={{ mt: { xs: 2, sm: 4 }, mb: { xs: 2, sm: 4 }, flexGrow: 1, px: { xs: 2, sm: 3 } }}>
        <Outlet />
      </Container>
      <Box component="footer" sx={{ py: 3, px: 2, mt: 'auto', backgroundColor: 'background.paper' }}>
        <Container maxWidth="sm">
          <Typography variant="body2" color="text.secondary" align="center">
            © {new Date().getFullYear()} Serga BA
          </Typography>
        </Container>
      </Box>
    </Box>
  );
};

export default MainLayout;