import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography, Paper, IconButton, InputAdornment, useTheme, useMediaQuery, MenuItem } from '@mui/material';
import Grid from '@mui/material/Grid2';
import DeleteIcon from '@mui/icons-material/Delete';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import CustomSnackbar from './CustomSnackbar';

const API_URL = process.env.REACT_APP_API_URL || 'https://api.serga.ba';

interface SignupData {
  [key: string]: {
    names: string[];
    bookedBy?: string;
    courtCount?: number;
    time?: string;
  };
}

const StyledCalendarIcon = styled(CalendarTodayIcon)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[400] : theme.palette.grey[700],
}));

const StyledTimeIcon = styled(AccessTimeIcon)(({ theme }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[400] : theme.palette.grey[700],
}));

const StyledDateTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputAdornment-root .MuiSvgIcon-root': {
    color: theme.palette.mode === 'dark' ? theme.palette.grey[400] : theme.palette.grey[700],
  },
  '& .MuiInputBase-input[type="date"]::-webkit-calendar-picker-indicator': {
    filter: theme.palette.mode === 'dark' ? 'invert(1)' : 'none',
  },
}));

const SquashSignup: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [name, setName] = useState('');
  const [signups, setSignups] = useState<SignupData>({});
  const [bookedBy, setBookedBy] = useState('');
  const [courtCount, setCourtCount] = useState<number | ''>('');
  const [selectedTime, setSelectedTime] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'warning' | 'error'>('warning');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const timeSlots = [
    '17:00', '18:00', '19:00', '20:00', '21:00', '22:00'
  ];

  useEffect(() => {
    fetchSignups();
  }, []);

  const fetchSignups = async () => {
    try {
      const response = await axios.get(`${API_URL}/squash-signups`);
      const signupsData = response.data.reduce((acc: SignupData, item: any) => {
        acc[item.date] = {
          names: item.names || [],
          bookedBy: item.bookedBy,
          courtCount: item.courtCount,
          time: item.time
        };
        return acc;
      }, {});
      setSignups(signupsData);
    } catch (error) {
      console.error('Error fetching signups:', error);
    }
  };

  const handleSignup = async () => {
    if (!selectedDate) {
      setSnackbarMessage('Please select a date before signing up.');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }

    if (selectedDate && name) {
      try {
        await axios.post(`${API_URL}/squash-signups`, { date: selectedDate, name });
        setName('');
        fetchSignups();
        setSnackbarMessage('Signup successful!');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      } catch (error) {
        console.error('Error adding signup:', error);
        setSnackbarMessage('Error adding signup. Please try again.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    }
  };

  const handleUpdateBooking = async () => {
    if (!selectedDate) {
      setSnackbarMessage('Please select a date before updating the booking.');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }

    if (!bookedBy || courtCount === '' || !selectedTime) {
      setSnackbarMessage('Please fill in all booking fields (Booked By, Number of Courts, and Time) before updating.');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
      return;
    }

    try {
      await axios.put(`${API_URL}/squash-signups`, {
        date: selectedDate,
        bookedBy,
        courtCount,
        time: selectedTime
      });
      setBookedBy('');
      setCourtCount('');
      setSelectedTime('');
      fetchSignups();
      setSnackbarMessage('Booking updated successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error updating booking:', error);
      setSnackbarMessage('Error updating booking. Please try again.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };


  const handleRemoveSignup = async (date: string, name: string) => {
    try {
      await axios.delete(`${API_URL}/squash-signups`, { data: { date, name } });
      fetchSignups();
    } catch (error) {
      console.error('Error removing signup:', error);
    }
  };

  const getDayName = (dateString: string) => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const date = new Date(dateString);
    return days[date.getDay()];
  };

  const isDateInFuture = (dateString: string) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const date = new Date(dateString);
    return date >= today;
  };

  const sortedFutureSignups = Object.entries(signups)
    .filter(([date]) => isDateInFuture(date))
    .sort(([dateA], [dateB]) => new Date(dateA).getTime() - new Date(dateB).getTime());

  const handlePasswordSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (password === 'squash123') {
      setIsAuthenticated(true);
    }
  };

  if (!isAuthenticated) {
    return (
      <Box component="form" onSubmit={handlePasswordSubmit} sx={{ mt: 1 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Squash Signup
        </Typography>
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Enter
        </Button>
      </Box>
    );
  }

  return (
    <Box sx={{ mt: 1 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Squash Signup
      </Typography>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 6 }}>
          <StyledDateTextField
            margin="normal"
            required
            fullWidth
            id="date"
            label="Date"
            type="date"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <StyledCalendarIcon />
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="name"
            label="Player Name"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleSignup}
          >
            SIGN UP
          </Button>
          <TextField
            margin="normal"
            fullWidth
            name="bookedBy"
            label="Booked By"
            id="bookedBy"
            value={bookedBy}
            onChange={(e) => setBookedBy(e.target.value)}
          />
          <TextField
            margin="normal"
            fullWidth
            name="courtCount"
            label="Number of Courts"
            id="courtCount"
            type="number"
            value={courtCount}
            onChange={(e) => setCourtCount(e.target.value === '' ? '' : Number(e.target.value))}
          />
          <TextField
            select
            margin="normal"
            fullWidth
            name="time"
            label="Time"
            id="time"
            value={selectedTime}
            onChange={(e) => setSelectedTime(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <StyledTimeIcon />
                </InputAdornment>
              ),
            }}
          >
            {timeSlots.map((time) => (
              <MenuItem key={time} value={time}>
                {time}
              </MenuItem>
            ))}
          </TextField>
          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleUpdateBooking}>
            UPDATE BOOKING
          </Button>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <Paper elevation={3} sx={{ p: 2, height: '100%', overflowY: 'auto' }}>
            <Typography variant="h6" gutterBottom>
              Current Signups
            </Typography>
            {sortedFutureSignups.map(([date, data]) => (
              <Box key={date} sx={{ mb: 2 }}>
                <Typography variant="subtitle1">
                  <CalendarTodayIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                  {`${date} (${getDayName(date)})`}
                </Typography>
                <Typography variant="body2">
                  Booked by: {data.bookedBy || 'Not booked yet'}
                </Typography>
                <Typography variant="body2">
                  Courts: {data.courtCount || 'Not specified'}
                </Typography>
                <Typography variant="body2">
                  Time: {data.time || 'Not specified'}
                </Typography>
                {data.names.map((name, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      py: 1,
                      borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
                    }}
                  >
                    <Typography variant="body2">{name}</Typography>
                    <IconButton
                      aria-label="delete"
                      size="small"
                      onClick={() => handleRemoveSignup(date, name)}
                    >
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Box>
                ))}
              </Box>
            ))}
          </Paper>
        </Grid>
      </Grid>
      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleSnackbarClose}
      />
    </Box>
  );
};

export default SquashSignup;